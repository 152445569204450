<template>
  <router-view/>
</template>

<script>
export default {
  name: "id"
}
</script>

<style lang="scss" scoped>

</style>
